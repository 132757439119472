/*eslint-disable*/
import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import cookie from 'vue-cookies'
import {
  ACCESS_TOKEN,
  USER,
  USER_EMAIL,
  ID_TOKEN,
} from '@/utils/const'

import axios from 'axios'
import router from '@/router'

const { Logger } = Amplify
Logger.LOG_LEVEL = process.env.NODE_ENV === 'production' ? 'ERROR' : 'DEBUG'
const logger = new Logger('store:auth')

const data = {
  user: cookie.get(USER) || null,
  idToken: cookie.get(ID_TOKEN) || '',
  authenticationStatus: null,
  userConfirmed: false,
  createUser: '',
  userEmail: cookie.get(USER_EMAIL) || '',
}

const getters = {
  authenticatedUser: (state) => state.user,
  isConfirmed: (state) => state.userConfirmed,
  isAuthenticated: (state) => !!state.idToken,
  accessToken: (state) => state.idToken,
  authenticationStatus: (state) =>
      state.authenticationStatus
          ? state.authenticationStatus
          : { variant: 'secondary' },
  hasAuthenticationStatus: (state) => !!state.authenticationStatus,
}

const mutations = {
  setUserEmail(state, email) {
    state.userEmail = email
  },
  setAuthenticationError(state, err) {
    state.authenticationStatus = {
      state: 'failed',
      message: err.message,
      variant: 'error',
    }
  },
  setAuthenticationSuccess(state, text) {
    state.authenticationStatus = {
      state: 'success',
      message: text,
      variant: 'success',
    }
  },
  clearAuthenticationStatus: (state) => {
    state.authenticationStatus = null
  },
  setUserAuthenticated(state, user) {
    const token = user.signInUserSession.accessToken.jwtToken
    const id_Token = user.signInUserSession.idToken.jwtToken
    cookie.set(USER, user.attributes)
    cookie.set(ACCESS_TOKEN, token)
    cookie.set(ID_TOKEN, id_Token)
    cookie.set(USER_EMAIL, user.attributes.email);
    state.user = user.attributes
    state.token = token
    state.idToken = id_Token
    state.userEmail = user.attributes.email;
    state.userConfirmed = true
  },
  setUserConfirmed(state, status) {
    cookie.remove(USER_EMAIL)
    state.userConfirmed = status
  },
  setNewPassword(state, payload) {
    state.createUser = payload
  },
  clearAuthentication(state) {
    cookie.remove(USER)
    cookie.remove(ACCESS_TOKEN)
    cookie.remove(ID_TOKEN)
    cookie.remove(USER_EMAIL)
    state.user = null
    state.token = ''
    state.idToken = ''
    state.userEmail = ''
    state.userConfirmed = false
  },
  setUserToken(state, token) {
    cookie.set(ID_TOKEN, token)
    state.idToken = token
  },
}

const actions = {
  clearAuthenticationStatus: (context) => {
    context.commit('clearAuthenticationStatus', null)
  },
  signIn: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true })
    try {
      const user = await Auth.signIn(params.username, params.password)
      if (
          'challengeName' in user &&
          user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        context.commit('setNewPassword', user)
      } else if (user.attributes['email_verified']) {
        context.commit('setUserEmail', user.attributes.email)
        const sendLoginOtpRequest = async (payload) => {
          const response = await axios.post('/otp/generate', payload)
          return response
        }

        const sendLoginOTP = async () => {
          try {
            const response = await sendLoginOtpRequest({
              appCode: '',
              emailAddress: user.attributes.email,
              mobileNumber: '',
              otp: '',
            })
            if (response.data.data === 'succeeded') {
              setTimeout(() => {
                router.push({ name: 'ConfirmLoginOTP' })
              }, 2500)
            }
          } catch (err) {
            context.commit('auth/setAuthenticationError', err, { root: true })
          }
        }

        await sendLoginOTP()
      } else {
        context.commit(
            'auth/setAuthenticationError',
            { message: 'unauthorized' },
            { root: true }
        )
      }
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true })
    }
  },
  signOut: async (context) => {
    try {
      await Auth.signOut()
    } catch (err) {
      logger.error('error during sign out: {}', err)
    }
    context.commit('auth/clearAuthentication', null, { root: true })
  },

  confirmResend: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true })
    try {
      await Auth.resendSignUp(params.username)
      context.commit(
          'auth/setAuthenticationSuccess',
          'Password reset code sent via email/phone',
          { root: true }
      )
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true })
    }
  },
  passwordReset: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true })
    try {
      await Auth.forgotPassword(params.username)
      context.commit(
          'auth/setAuthenticationSuccess',
          'Password reset code sent via email/phone',
          { root: true }
      )
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true })
    }
  },
  confirmPasswordReset: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true })
    try {
      await Auth.forgotPasswordSubmit(
          params.username,
          params.code,
          params.password
      )
      context.commit(
          'auth/setAuthenticationSuccess',
          'Password reset successful, Sign in',
          { root: true }
      )
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true })
    }
  },
  validateLoginOTP: async (context, params) => {
    try {
      const response = await axios.post('/otp/validate', {
        appCode: '',
        emailAddress: context.state.userEmail,
        mobileNumber: '',
        otp: params.otp,
      })
      if (response.data.data === true) {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        context.commit('setUserAuthenticated', cognitoUser)
        context.commit('auth/setAuthenticationSuccess', 'logged in', {
          root: true,
        })
      } else {
        context.commit('auth/setAuthenticationError', {
              message: response.data.responseStatus.description || 'Invalid OTP',
            },
            { root: true }
        )
      }
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true })
    }
  },

  refresh: async (context) => {
    try {
      const currentSession = await Auth.currentSession()
      context.commit('setUserToken', currentSession.idToken.jwtToken)
    } catch (e) {
      throw Error(e)
    }
  },
}

export const sendLoginOtpRequest = async (payload) => {
  const response = await axios.post('/otp/generate', payload)
  return response
}

export default {
  namespaced: true,
  state: data,
  getters,
  actions,
  mutations,
}
